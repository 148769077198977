import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [

  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    name: 'Main',
    component: Home
  },
  {
    // will match everything and put it under `$route.params.pathMatch`
    path: '/:pathMatch(.*)*',
    name: 'Err404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Err404.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/legal',
    name: 'Legal',
    component: () => import('../views/Legal.vue')
  }
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
