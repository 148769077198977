<template lang="html">
    <div>
        <footer id="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav class="footer-links pt-2 pt-lg-0 text-center">
                            <!-- 
                            <router-link to="/">{{ $trs('footer.home') }}</router-link>
                            <router-link to="/#contact">{{ $trs('footer.contact') }}</router-link>
                            <router-link to="/privacy">{{ $trs('footer.privacy') }}</router-link>
                            -->
                        </nav>
                    </div>
                </div>
            </div>
        </footer>
        <a href="#" class="back-to-top" v-show="scrolled"><i class="fas fa-chevron-up"></i></a>  <!-- font-awesome icon -->
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {
                scrolled: window.scrollY > 100
            }
        },
        methods: {
            handleScroll() {
                this.scrolled = window.scrollY > 100;
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        unmounted() {
            window.removeEventListener('scroll', this.handleScroll);
        },
    }
</script>





<style lang="css" scoped>

    #footer {
        background: #75b4d3; /* secondary theme light */
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
        padding: 30px 0;
        font-size: 14px;
    }

    #footer .footer-links a {
        color: #fff;  /* white */   
        padding-left: 15px;
    }

    #footer .footer-links a:hover {
        color: #09667f; /* primary theme dark */    
    }

    /* footer revealed */
    #main {
        margin-bottom: 150px;
    }

    #footer {
        position: fixed;
        z-index: -1;
        height: 150px; /*  same as main margin bottom (see 'feymargins')*/
        width: 100%;
        bottom: 0;
    }


    /*
    --------------------------------------------------------------
    # Back to top button
    --------------------------------------------------------------
    */
    .back-to-top {
        position: fixed;
        background: #75b4d3; /* secondary theme light */   
        color: #fff; /* white */
        padding: 2px 20px 8px 20px;
        font-size: 16px;
        border-radius: 4px 4px 0 0;
        right: 0;
        bottom: 0;
        transition: none;
        z-index: 99;
    }

    .back-to-top:focus {
        background: #09667f; /* primary theme dark */     
        color: #fff;  /* white */
        outline: none;
    }

    .back-to-top:hover {
        background: #09667f; /* primary theme dark */     
        color: #fff; /* white */
    }

</style>
