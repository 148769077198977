<template>
    <div>
        <Header :animated="true"/>
        
        <div class="feymargins" v-scroll-spy>

            <div id="gallery">
                <div class="container">
                    <div class="section-header">
                        <h1>{{ $trs('home.intro.title') }}</h1>
                        <h2>{{ $trs('home.gallery.title') }}</h2>
                        <div class="separator"></div>
                        <!-- <p v-html="$trs('home.gallery.claim')"></p>  -->
                        <div class="mb-2"></div> 
                    </div>


                    <div class="row g-0">

                        <div class="col-lg-4 col-md-12">
                            <div class="gallery-item wow fadeInUp">
                                <div class="gallery-popup">
                                    <img src="@/img/gallery/gallery-1.jpg" alt="" />
                                </div>
                                <div class="gallery-text">
                                    <h2>{{ $trs('home.gallery.title1') }}</h2>
                                    <p v-html="$trs('home.gallery.text1')"></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="gallery-item wow fadeInUp">
                                <div class="gallery-popup">
                                    <img src="@/img/gallery/gallery-2.jpg" alt="" />
                                </div>
                                <div class="gallery-text">
                                    <h2>{{ $trs('home.gallery.title2') }}</h2>
                                    <p v-html="$trs('home.gallery.text2')"></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="gallery-item wow fadeInUp">
                                <div class="gallery-popup">
                                    <img src="@/img/gallery/gallery-3.jpg" alt="" />
                                </div>
                                <div class="gallery-text">
                                    <h2>{{ $trs('home.gallery.title3') }}</h2>
                                    <p v-html="$trs('home.gallery.text3')"></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="gallery-item wow fadeInUp">
                                <div class="gallery-popup">
                                    <img src="@/img/gallery/gallery-4.jpg" alt="" />
                                </div>
                                <div class="gallery-text">
                                    <h2>{{ $trs('home.gallery.title4') }}</h2>
                                    <p v-html="$trs('home.gallery.text4')"></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="gallery-item wow fadeInUp">
                                <div class="gallery-popup">
                                    <img src="@/img/gallery/gallery-5.jpg" alt="" />
                                </div>
                                <div class="gallery-text">
                                    <h2>{{ $trs('home.gallery.title5') }}</h2>
                                    <p v-html="$trs('home.gallery.text5')"></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="gallery-item wow fadeInUp">
                                <div class="gallery-popup">
                                    <img src="@/img/gallery/gallery-6.jpg" alt="" />
                                </div>
                                <div class="gallery-text">
                                    <h2>{{ $trs('home.gallery.title6') }}</h2>
                                    <p v-html="$trs('home.gallery.text6')"></p>
                                </div>
                            </div>
                        </div>

                    </div>


                    <a href="#intro" class="button-more"><i class="fas fa-chevron-down"></i></a> 
                    <div class="row mb-5"></div>    
                    <div class="row mb-5"></div>    
                </div>
            </div>

            <div id="intro">
                    <div class="row mb-5"></div>    
                    <div class="section-header intro-top">
                        <h1>{{ $trs('home.intro.subtitle1') }}</h1>
                        <h1>{{ $trs('home.intro.subtitle2') }}</h1> 
                        <div class="separator"></div>
                        <p v-html="$trs('home.intro.claim')"></p>
                    </div>

                    <div class="intro-pictures container">
                        <div class="row row-cols-1 row-cols-lg-3 g-4">
                            <div class="col">
                                <div class="card h-100 border-0">
                                    <img src="@/img/photo1.jpg" class="card-img-top" alt="Results">
                                    <div class="card-body">
                                        <h5 class="card-title">{{ $trs('home.intro.card1.title') }}</h5>
                                        <p class="card-text" >{{ $trs('home.intro.card1.text') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 border-0">
                                    <img src="@/img/photo2.jpg" class="card-img-top" alt="...">
                                    <div class="card-body">
                                        <h5 class="card-title">{{ $trs('home.intro.card2.title') }}</h5>
                                        <p class="card-text">{{ $trs('home.intro.card2.text') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 border-0">
                                    <img src="@/img/photo3.jpg" class="card-img-top" alt="...">
                                    <div class="card-body">
                                        <h5 class="card-title">{{ $trs('home.intro.card3.title') }}</h5>
                                        <p class="card-text">{{ $trs('home.intro.card3.text') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>                

                    </div> 
                    <div class="row mb-5"></div>    
                    <a href="#contact" class="button-more"><i class="fas fa-chevron-down"></i></a> 
                    <div class="row mb-5"></div>    
                    <div class="row mb-5"></div>    
                
            </div>
            
            <div id="contact">
                <div class="container">

                    <div class="section-header">
                        <h1 v-html="$trs('home.contact.title')"></h1>
                        <div class="separator"></div>
                        <p v-html="$trs('home.contact.claim')"></p>
                        <div class="mb-4"></div>
                    </div>
                

                    <div class="row">

                        <div class="col-lg-3 col-md-3 wow bounceInUp">
                        </div>    

                        <div class="col-lg-6 col-md-6 wow bounceInUp">
                            <div class="contact-form" :key="$i18nL()"> <!-- :key : forces refresh of placeholders at language change -->
                                <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }" >
                                    <div class="row">
                                        <div class="form-group col-lg-6 mb-3">
                                            <Field type="text" class="form-control" name="name" :placeholder="$trs('home.contact.name')" :class="{ 'is-invalid': errors.name }" />
                                            <div class="invalid-feedback">{{ $trs(errors.name) }}</div>
                                        </div>
                                        <div class="form-group col-lg-6 mb-3">
                                            <Field type="email" class="form-control" name="email" :placeholder="$trs('home.contact.email')" :class="{ 'is-invalid': errors.email }" />
                                            <div class="invalid-feedback">{{ $trs(errors.email) }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-3">
                                        <Field type="text" class="form-control" name="subject" :placeholder="$trs('home.contact.subject')"  :class="{ 'is-invalid': errors.subject }" />
                                        <div class="invalid-feedback">{{ $trs(errors.subject) }}</div>
                                    </div>
                                    <div class="form-group mb-3">
                                        <Field as="textarea" class="form-control" rows="4" name="message" :placeholder="$trs('home.contact.message')"  :class="{ 'is-invalid': errors.message }" />
                                        <div class="invalid-feedback">{{ $trs(errors.message) }}</div>
                                    </div>
                                    <div class="text-center mb-3" :class="contactform.forbidden">
                                        <button type="submit">{{ $trs('home.contact.send') }}</button>
                                    </div>
                                </Form>
                                <p :class="contactform.result">{{ contactform.resultMessage }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-5">
                    </div>    
                    <div class="row mb-5">
                    </div>    
                    <div class="row mb-5">
                    </div>    
                </div>
            </div>
        </div>

        <Footer />
    </div>
</template>




<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Form,
    Field
  },
  data() {
    return {
      contactform: {
          resultMessage: '',
          result: 'hidden',
          forbidden: ''
      }
    }
  },
  methods: {
    onSubmit(values) {
      var vm = this;
      var config = {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      };
      var data = JSON.stringify(values);
      axios.post(process.env.VUE_APP_API_URL+'/api/home-email-contact.php', data, config)
      .then(function(response) {
        if (response.data.success) {
          vm.contactform.resultMessage = vm.$trs('home.msg.success');
          vm.contactform.result = 'alert alert-success';
          vm.contactform.forbidden = 'forbidden';
        } else {
          console.log("response.data.msg="+response.data.msg);
          vm.contactform.resultMessage = vm.$trs('home.msg.failure', {'error': response.data.msg});
          vm.contactform.result = 'alert alert-danger';
        }
      })
      .catch(function(error) {
         console.log(error);
         vm.contactform.resultMessage = vm.$trs('home.msg.failure', {'error': 'error'});
         vm.contactform.result = 'alert alert-danger';
      });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      name: Yup.string().required('home.contact.name-required'),
      email: Yup.string().required('home.contact.email-required').email('home.contact.email-invalid'),
      subject: Yup.string().required('home.contact.subject-required'),
      message: Yup.string().required('home.contact.message-required')
    });
    return {
        schema
    };
  },
}
</script>







<style scoped>

    
    
/*
--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------
*/

.intro-pictures {
    margin-top: 25px;
    width: 80%;
}


.card-img-top {
    width: 90%;
}

.card-title {
    font-weight: bold;
    color: #fff;  /* white */  
}

.card-body {
    margin-top: -60px;
    margin-right: 0;
    margin-left: 25px;   
    padding : 10px 10px 25px 10px;
    background-color: #09667f; /* primary theme dark */     
}

.card-body p {
    color: #fff;  /* white */  
}

@media (min-width: 701px) {
    .intro-top {
        margin-top: 100px;
        transition: 1s ease; 
    }
}

@media (max-width: 700px) {
    .intro-top {
        margin-top: 140px;
        transition: 1s ease; 
    }
}

.button-more {
    position: relative;
    background: #75b4d3; /* secondary theme light */   
    color: #fff; /* white */
    padding: 8px 20px 2px 20px;
    font-size: 16px;
    border-radius: 4px;
    margin-left: -25px;
    left: 50%;
    top: 40px;
    transition: none;
    z-index: 99;
}

.button-more:focus {
    background: #09667f; /* primary theme dark */     
    color: #fff;  /* white */
    outline: none;
}

.button-more:hover {
    background: #09667f; /* primary theme dark */     
    color: #fff; /* white */
}

/*
--------------------------------------------------------------
# Section gallery
--------------------------------------------------------------
*/

#gallery {
    background: #fff;
    padding: 30px 0 0 0;
    overflow: hidden;
}

#gallery .gallery-item {
    overflow: hidden;
    position: relative;
    padding: 0;
    vertical-align: middle;
    text-align: center;
}

#gallery .gallery-item img {
    -webkit-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
    width: 100%;
}

#gallery .gallery-item:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

#gallery .gallery-popup {
    overflow: hidden;
}

#gallery .gallery-text {
    background: #fff; /* white */   
    margin: 0 0 20px 0;
}

#gallery .gallery-text h2 {
    font-weight: bold;
    padding: 10px 0 0 0;
    margin: 0;
}

#gallery .gallery-text p {
    padding: 5px 0 0 0;
    margin: 0;
    line-height: 1.2em;
}


/*
--------------------------------------------------------------
# Section Contact
--------------------------------------------------------------
*/
#contact {
    background: #fff;
    padding: 30px 0;
    overflow: hidden;
}


#contact .hidden {
    display: none;
}

#contact .message {
    padding: 15px;
    margin: 0;
}

#contact .contact-form .form-control::placeholder {
    color: #7f9dc4;     /* light grey blue primary */
}

#contact .alert {
    margin-top: 10px;
}
#contact .contact-form button[type="submit"] {
    background: #75b4d3; /* secondary theme light */   
    border: 0;
    border-radius: 20px;
    padding: 8px 30px;
    color: #fff;
}

#contact .contact-form button[type="submit"] {
    cursor: pointer;
}

#contact .contact-form .forbidden button[type="submit"] {
    cursor: not-allowed;
}
</style>
