<template lang="html">
    <header >
        <nav class="navbar navbar-expand-lg navbar-dark fixed-top navbar-fade" :class="scrolled ? 'navbar-bg' : ''">
            <div class="container-fluid">

                <!-- logo -->
                <a class="navbar-brand" href="/" :class="scrolled ? 'iconsmall' : 'iconbig'">
                    <img src="@/img/logo.png" class="d-inline-block align-top" alt=""/>
                </a>

                <!-- right-hand menu (end) -->
                <div class="navbar-nav">
                    <span class="nav-item" :key="language" v-for="language in availableLanguages" >
                        <span class="bar">{{ getBar(language)}}</span><a class="nav-link" :class="isCurrentLanguage(language) ? 'active' : ''" href="#" @click="changeLanguage(language)">{{ $trs('header.'+language) }}</a>
                    </span>
                </div>
            </div>
        </nav>

        <!-- Cookie consent (:locale property is not used in component, but this binding data it is just to trigger child component refresh on language change)-->
        <Consent :locale="$i18nL()"/>

    </header>
</template>







<script>
    import Consent from '@/components/Consent.vue';
    import i18next from 'i18next';

    export default {
        name: 'Header',
        components: {
            Consent
        },
        props: {
            animated: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                availableLanguages: i18next.languages, // [ 'en', 'fr' ],
                scrolled: window.scrollY > 100 || !this.animated
            }
        },
        mounted() {
            document.title = this.$trs('title');
        },
        methods: {
            getLink(link) {
                return process.env.VUE_APP_HOME + link;
            },
            changeLanguage(key) {
                i18next.changeLanguage(key).then(this.$forceUpdate());
                document.title = this.$trs('title');
            },
            isCurrentLanguage(lng) {
                return i18next.language === lng;
            },
            getBar(lng) {
                return navigator.language.substring(0,2) === lng ? "" : "|";
            },
            handleScroll() {
                this.scrolled = window.scrollY > 100 || !this.animated;
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        unmounted() {
            window.removeEventListener('scroll', this.handleScroll);
        },
    }
</script>






<style lang="css" scoped>

    .navbar-fade {
        transition: all linear 0.8s;
    }

    .navbar-bg {
        background: #09667f; /* primary theme dark */
    }



    .navbar {
        -webkit-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
        -o-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
        transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .navbar-dark .navbar-brand {
        color: #75b4d3; /* secondary theme light */
    }

    .iconbig img {
        width: 132px;
        transition: width 1s;
    }

    .iconsmall img {
        width: 56px;
        transition: width 1s;
    }

    .navbar-dark .navbar-brand:hover {
        color: #75b4d3; /* secondary theme light */
    }

    .navbar-dark .navbar-nav .nav-link {
        align-items: top;
        color: rgba(117, 180, 211, .8);  /* secondary theme light */
        margin: 1px;
        border-radius: 8px;
        display: inline;
    }

    .navbar-dark .navbar-nav .nav-link.active {
        align-items: top;
        color: rgba(117, 180, 211, .8);  /* secondary theme light */
        font-weight: bold;
        margin: 1px;
        border-radius: 8px;
    }

    .navbar-dark .navbar-nav .nav-link:hover {
        align-items: top;
        color: #09667f; /* primary theme dark */    
        margin: 1px;
        background: #75b4d3; /* secondary theme light */
    }

    .navbar-nav {
        display: inline;
    }

    .bar {
        display: inline;
        color: #75b4d3; /* secondary theme light */
    }


</style>
