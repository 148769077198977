<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@font-face {
  font-family: "AndikaNewBasic-Regular";
  src: url("assets/fonts/AndikaNewBasic-Regular.ttf");
  /* font-weight: Bold; */
}

/*
--------------------------------------------------------------
# Colors
--------------------------------------------------------------
*/
colortheme {
    color: #09667f; /* primary theme dark */                                     /* font over white, primary background */
    color: rgba(9, 102, 127, .5);  /* primary theme dark */                      /* font over white, primary background */
    color: #75b4d3; /* secondary theme light */                                  /* font over white, font over dark theme, secondary background */
    color: rgba(117, 180, 211, .5);  /* secondary theme light */                 /* font over white, font over dark theme, secondary background */
    color: #111;  /* black */                                                    /* alternative text font color over white */
    color: #fff;  /* white */                                                    /* main background (or font over dark theme */
}
    

body {
    background: #fff;  /* white */
    color: #111 !important; /* black */
    font-family: "AndikaNewBasic-Regular", -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif!important;
    overflow-x: hidden;
    scroll-behavior: smooth;
    position: relative;  
    overflow-y: scroll;
}


.feymargins {
    padding: 0;
    overflow: hidden;
    background: #fff;  /* white */
    margin-bottom: 150px;
}


.section-header {
    margin-top: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    text-align: center;
}

@media (min-width: 701px) {
    h1 {
        margin-bottom: 15px;
        padding: 0 15px;
        font-weight: bold;
        font-size: 56px;
        transition-property: font-size;
        transition-duration: 1s;
        transition-timing-function: ease;
        color: #09667f; /* primary theme dark */     
    }

    h2 {
        margin-bottom: 10px;
        padding: 0 15px;
        font-size: 24px;
        transition-property: font-size;
        transition-duration: 1s;
        transition-timing-function: ease;
        color: #09667f; /* primary theme dark */     
    }
}

@media (max-width: 700px) {
    h1 {
        margin-bottom: 15px;
        padding: 0 15px;
        font-weight: bold;
        font-size: 36px;
        transition-property: font-size;
        transition-duration: 1s;
        transition-timing-function: ease;
        color: #09667f; /* primary theme dark */     
    }

    h2 {
        margin-bottom: 10px;
        padding: 0 15px;
        font-size: 20px;
        transition-property: font-size;
        transition-duration: 1s;
        transition-timing-function: ease;
        color: #09667f; /* primary theme dark */     
    }
}

.separator {
    display: block;
    border: 2px solid #75b4d3; /* secondary theme light */   
    border-radius: 100px;
    width: 80px;
    margin: 10px 0;
    text-align: center;
}


p {
    font-style: italic;
    margin: 5px 0 5px 0;
    padding: 0 15px;
    font-size: 16px;
    color: #09667f; /* primary theme dark */     
}


a {
  text-decoration: none!important;
}




</style>
