<template lang="html">
    <transition name="slideFromBottom" appear>
        <div class="consent" v-show="isVisible">
            <slot></slot>
            <div class="container">
                <div class="container-text">
                    <span>{{ $trs("cookie-consent.text") }}&nbsp;&nbsp;&nbsp;
                        <!-- <router-link class="link" to="/faq#cookie">{{ $trs("cookie-consent.link") }}</router-link>  -->
                    </span>
                    <div class="buttons">
                        <button class="btn btn-primary" @click="accept()">{{ $trs("cookie-consent.accept") }}</button>
                        <button class="btn btn-danger m-2" @click="decline()">{{ $trs("cookie-consent.decline") }}</button>
                    </div> 
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import * as Cookie from 'tiny-cookie';

    const cookieName = "consent-banner";

    export default {
        name: 'Consent',
        data() {
            return {
                isVisible: this.showconsent()
            }
        },
        methods: {
            showconsent() {
                let visited = Cookie.get(cookieName)
                if (typeof visited === 'string') {
                    visited = JSON.parse(visited)
                }
                return (visited === null || visited === undefined);
            },
            accept() {
                Cookie.set(cookieName, true, {
                    expires: '1Y'
                });
                this.isVisible = false;
            },
            decline() {
                // Cookie.remove(cookieName);
                Cookie.set(cookieName, false, {
                    expires: '1Y'
                });
                this.isVisible = false;
            },
        }
    }
</script>

<style lang="css" scoped>
    
    .consent {
        position: fixed;
        background: #fff;
        padding: 8px;
        border-radius: 8px;
        bottom: 64px;
        z-index: 999999;
        width: 100%;
        box-shadow: 0 12px 34px 0 rgba(0,0,0,0.15);
    }

    .link {
        font-weight: 700;
        color: #09667f; /* primary theme dark */  
    }

    @media (min-width: 701px) {
        .consent {
            max-width: 496px;
            left: 64px;
        }
        .buttons {
            display: flex;
            align-items: center;
            flex-direction: row;
        }
    }

    @media (max-width: 701px) {
        .consent {
            max-width: 280px;
            left: 20px;
        }
        .buttons {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }

    .consent .container .container-text {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 8px;
        color: #09667f; /* primary theme dark */  
    }


    .slideFromTop-enter-from, .slideFromTop-leave-to {
        transform: translate(0px, -12.500em);
    }
    .slideFromTop-enter-to, .slideFromTop-leave {
        transform: translate(0px, 0px);
    }
    .slideFromBottom-enter-from, .slideFromBottom-leave-to {
        transform: translate(0px, 12.500em);
    }
    .slideFromBottom-enter-to, .slideFromBottom-leave {
        transform: translate(0px, 0px);
    }
    .slideFromBottom-enter-active,
    .slideFromBottom-leave-active,
    .slideFromTop-enter-active,
    .slideFromTop-leave-active {
        transition: transform 1s ease-in;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s ease;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }

    .btn-primary {
        background-color: #75b4d3; /* secondary theme light */   
        border-color: #09667f; /* primary theme dark */   
        color: #09667f; /* primary theme dark */  
    }

    .btn-danger {
        background-color: #fff; /* white */   
        color: #09667f; /* primary theme dark */  
    }

</style>
