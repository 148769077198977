// Basic app
// Bootstrap styling frameworks
import bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Fontawesome icons
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Languages (https://www.i18next.com/)
import i18next from 'i18next';

// Connection to api server
import axios from 'axios';
import VueAxios from 'vue-axios';

// Animations
// - scroll-spy for menu highlight when scrolling page
// - wowjs/animate : moving elements (do not use for SEO critical elements) - Use more recent version of animate (in asset)
import { registerScrollSpy } from 'vue3-scroll-spy';
// import 'wowjs/css/libs/animate.css';
import '@/assets/css/animate.min.css';

// Create app
const app = createApp(App)
.use(router)
.use(bootstrap)
.use(i18next)
.use(VueAxios, axios)
;

// Init i18n support
import en from './locales/en.json';
import fr from './locales/fr.json';

i18next
.init({
  initImmediate: false,
  fallbackLng: [ "en", "fr" ],
  lng: navigator.language.substring(0,2),
  supportedLngs: [ "en", "fr" ],
  debug: true,

  resources: {
    en: {
      translation: en
    },
    fr: {
      translation: fr
    },
  }
});

// Define shortcut function
app.config.globalProperties.$trs = (keys, options) => {
  return (keys === undefined || keys === '') ? '' : i18next.t(keys, options);
};
app.config.globalProperties.$i18nL = () => {
  return i18next.language;
};

// register scroll-spy directives
registerScrollSpy(app);

// init wowjs animation
import { WOW } from 'wowjs';
const wow = new WOW({
  boxClass: 'wow',
  offset: 100,
  live: false
  // callback:     function(box) {
  //   console.log("WOW: animating <" + box.tagName.toLowerCase() + ">");
  // },
  }
);
wow.init();

// Launch app
app.mount('#app');
